var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { on: { mousemove: _vm.updateMouse } },
    [
      _c("router-view"),
      _c(
        "v-snackbar",
        {
          staticClass: "application",
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            bottom: true,
            left: true,
          },
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.snackbarMessage) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: _vm.snackbarBtnColor, flat: "" },
              on: {
                click: function ($event) {
                  _vm.showSnackbar = false
                },
              },
            },
            [_vm._v("\n      Close\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }