import JwtDecode from 'jwt-decode'

export default class User {
  static from (token) {
    try {
      let obj = JwtDecode(token)
      return new User(obj)
    } catch (_) {
      return {
        isLoggedIn: false
      }
    }
  }

  constructor ({ auth, em, fn, exp }) {
    this.isLoggedIn = true
    this.auth = auth
    this.email = em
    this.fullname = fn
    this.expire = exp
  }

  get name() {
    return this.fullname? this.fullname : this.email
  }

  get isCompliance() {
    return this.auth.split(',').includes('ROLE_L_CM')
  }

  get isManager() {
    return this.auth.split(',').includes('ROLE_L_PM')
  }

  get isEditor() {
    return this.auth.split(',').includes('ROLE_PEND_PROP_ED')
  }
}
