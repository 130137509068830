import Vue from 'vue'
import VueAxios from 'vue-axios'

import axios from './axios'

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    //console.error('error ' + JSON.stringify(error))
    if (error.response && 401 === error.response.status) {
      const _this = window.vm
      _this.$dialog
        .confirm({
          title: 'Session Expired',
          text: 'Your session has expired. Please sign in again.',
          actions: {
            true: {
              text: 'Ok',
              color: 'primary',
            },
          },
        })
        .then(answer => {
          if (answer) {
            if (_this.$route.path !== '/login') {
              //_this.$store.dispatch('logout')
              _this.$router.replace('/login?redirect=' + _this.$route.path)
            }
          }
        })
    } else {
      return Promise.reject(error)
    }
  }
)

Vue.use(VueAxios, axios)
