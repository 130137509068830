import Vue from 'vue'
import Vuetify, { VTextField } from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#2196F3'
  },
  components: {
    VTextField
  }
})
