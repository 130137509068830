<template>
  <div class="mb-5 text-xs-center">
    <img src="../assets/TE-Icons.svg" height="40" class="mb-2">
    <h2 class="title mb-4">{{title}}</h2>
    <p class="text-muted">{{subtitle}}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
  },
  data () {
    return {

    }
  }
}
</script>
