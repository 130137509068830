import User from '../models/User';
import { version } from '../../package.json';

export default {
  version: version,
  user: User.from(localStorage.token),
  pdfFile: null,
  pdfData: null,
  pdfUrl: null,
  pdfChanges: null,
  app: {
    propertyId: null,
    status: null,

    propertyName: null,
    entityType: null,
    einNumber: null,
    businessOrigin: null,
    houseNo: null,
    street: null,
    streetType: null,
    apartmentNo: null,
    city: null,
    state: null,
    zipcode: null,

    businessYears: null,
    unitsNumber: null,
    businessEmail: null,
    businessWebsite: null,

    propertyPhone: null,
    propertyPhoneExt: null,
    accountsPayableName: null,
    accountsPayableEmail: null,
    pmFirstName: null,
    pmLastName: null,
    pmPhone: null,
    entityId: null,
    notifyName: null,
    notifyEmail: null,

    creditReportUse: null,
    agreeNoResell: null,
    getDecisionLetter: null,

    authUserName: null,
    authUserEmail: null,

    caComplianceInfo: null,
    vtComplianceInfo: null,

    allowLeases: null,
    allowPurchases: null,
    allowGuests: null,

    leaseMin: null,
    leaseAllowPets: null,
    leaseMaxPetWeight: null,
    leaseMaxPetLimit: null,

    purchaseAllowPets: null,
    purchaseMaxPetWeight: null,
    purchaseMaxPetLimit: null,

    guestAllowPets: null,
    guestMaxPetWeight: null,
    guestMaxPetLimit: null,

    extraDocumentLimitGuest: null,
    extraDocumentLimitPurchase: null,
    extraDocumentLimitTenant: null,

    onboardingDocuments: null,

    leaseDocs: null,
    purchaseDocs: null,
    guestDocs: null,

    propertyNotes: null,

    fundAccount: null,
    achComments: null,
    achAccountType: null,
    achInstitutionName: null,
    achCity: null,
    achState: null,
    achZip: null,
    achRoutingNumber: null,
    achAccountNumber: null,
    agreeACHTerms: null,

    requireMinScore: null,
    creditScore: null,

    signerName: null,
    signerTitle: null,
    signingDate: null,
    signature: null,

    isBlacklist: null,
    blacklistNotes: null,

    comments: null,

    users: [],
    stepUsers: false,
    stepDocuments: false,

    splitManagement: null,
    splitCommunity: null,

    qaLetterDocUrl: null,
    deluxeFeeDocUrl: null,
  }
};
