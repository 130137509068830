import { ACTION_TYPES, MUTATION_TYPES } from '../libs/constants'

export default {
  [ACTION_TYPES.LOGIN] ({ commit }) {
    commit(MUTATION_TYPES.LOGIN)
  },

  [ACTION_TYPES.LOGOUT] ({ commit }) {
    commit(MUTATION_TYPES.LOGOUT)
  },

  [ACTION_TYPES.UPDATE_APP_PROPERTIES] ({ commit }, payload) {
    commit(MUTATION_TYPES.UPDATE_APP_PROPERTIES, payload)
  },

  [ACTION_TYPES.RESET_APP_PROPERTIES] ({ commit }) {
    commit(MUTATION_TYPES.RESET_APP_PROPERTIES)
  },

  //PDF STARTS
  [ACTION_TYPES.PDF_FILE]({ commit },val) {
    commit(MUTATION_TYPES.PDF_FILE,val)
  },
  [ACTION_TYPES.PDF_DATA]({ commit },val) {
    commit(MUTATION_TYPES.PDF_DATA,val)
  },
  [ACTION_TYPES.PDF_URL]({ commit },val) {
    commit(MUTATION_TYPES.PDF_URL,val);
  },
  [ACTION_TYPES.PDF_CHANGES]({ commit },val) {
    commit(MUTATION_TYPES.PDF_CHANGES,val);
  },
  [ACTION_TYPES.REMOVE_PDF_DATA]({ commit }) {
    commit(MUTATION_TYPES.REMOVE_PDF_DATA)
  },
  //PDF ENDS  
}
