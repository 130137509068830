<template>
  <div v-on:mousemove="updateMouse">
    <router-view></router-view>
    <v-snackbar class="application" v-model="showSnackbar" :color="snackbarColor" :timeout="snackbarTimeout" :bottom="true" :left="true">
      {{ snackbarMessage }}
      <v-btn :color="snackbarBtnColor" flat @click="showSnackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import vueinterval from 'vue-interval/dist/VueInterval.common'
import {
  mapGetters
} from 'vuex'
import crypto from 'crypto'

export default {
  data: () => ({
    showSnackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    snackbarBtnColor: 'white',
    snackbarTimeout: 10000,
    lastMove: null
  }),
  mixins:[vueinterval],
  mounted() {
    const _this = this
    this.$events.$on('showSnackbar', data => {
      _this.showSnackbar = false;
      _this.snackbarColor = data.color || '';
      _this.snackbarMessage = data.message;
      //_this.snackbarTimeout = data.timeout;
      _this.showSnackbar = true;
    });
    this.$on('offline', () => {
      this.$events.$emit('showSnackbar', {
        color: 'red',
        message: 'You are offline!'
      })
    })
    this.$on('online', () => {
      this.$events.$emit('showSnackbar', {
        color: 'green',
        message: 'Welcome back! You are online'
      })
    })

    //this.initBeacon()
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  watch: {
    currentUser() {
      //this.identifyBeacon()
    }
  },
  methods: {
    identifyBeacon() {
      if(this.currentUser.isLoggedIn) {

        if(this.currentUser.isManager) {
          // eslint-disable-next-line
          const sk = 'î¼B³5®iÈdüôðñéþpTäÒì"X¹¸'

          const sig = crypto.createHmac('sha256', btoa(sk))
            .update(this.currentUser.email)
            .digest('hex')

          // eslint-disable-next-line
          Beacon("identify", {
            name: this.currentUser.fullname,
            email: this.currentUser.email,
            signature: sig
          })

          // eslint-disable-next-line
          Beacon('config', {
            "hideFABOnMobile": true,
            "enableFabAnimation": true,
            "display": {
              "style": "icon",
              "iconImage": "question"
            },
          })
        }
      }
      else {
        // eslint-disable-next-line
        Beacon("logout")

        // eslint-disable-next-line
        Beacon('config', {
          "display": {
            "style": "manual"
          },
        })
      }
    },
    initBeacon() {
      // eslint-disable-next-line
      Beacon('init', 'df968de4-3fa3-4ebb-a71b-ebe969049e60')
      this.identifyBeacon()
    },
    INTERVAL__1e4$isUserActive() {
      if (this.lastMove==0 || !this.lastMove) {
        this.updateMouse()
        return
      }
      if(Date.now()-this.lastMove > 900000 && this.currentUser.isLoggedIn) {
        this.$router.push('/logout')
      }
    },
    updateMouse() {
      this.lastMove = Date.now()
    }
  }
}
</script>
<style>
html {
  font-size: 16px;
}

.theme--light.application {
  background: #F8F8F8;
  color: rgba(0, 0, 0, 0.6);
}

.theme--light.v-toolbar {
  background-color: #373041;
  color: rgba(0255, 255, 255, 1);
}

form.v-form {
  max-width: 800px;
  margin: 0 auto;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.theme--light.v-text-field--box > .v-input__control > .v-input__slot {
  background: rgba(255, 255, 255, 1);
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(255, 255, 255, 1);
}

.theme--light.v-text-field--box:not(.v-input--is-focused) > .v-input__control > .v-input__slot:hover {
  background: rgba(255, 255, 255, 1);
}

.theme--light.v-text-field:not(.v-input--has-state) > .v-input__control > .v-input__slot:hover:before {
  border-color: rgba(255, 255, 255, 1);
}

.application .primary {
  background-color: #2196F3 !important;
  border-color: #2196F3 !important;
}

.v-form > .container > .layout > .flex {
  padding: 4px;
}

.v-card__actions {
  max-width: 800px;
  margin: 0 auto;
}

.radio-user-perms .v-input--selection-controls .v-input__control {
  width: auto;
  max-width: 300px;
}

.radio-user-perms .v-radio,
.radio-user-perms .v-input--selection-controls__input {}

.radio-user-perms .v-input--selection-controls__input {
  margin: 0 auto;
}

.radio-user-perms .v-input--selection-controls.v-input .v-label {
  top: 4px;
}

#signature {
  background: #efefef;
  margin: 8px 0;
}

.credit-score .v-slider__track__container,
.credit-score .v-slider__track,
.credit-score .v-slider__track-fill {
  height: 14px;
}

.credit-score .v-slider__track {
  background-image: linear-gradient( to right, #F15A24 0%, #F15A24 25%, #FBB03B 25%, #FBB03B 50%, #8CC63F 50%, #8CC63F 75%, #0A903B 75%, #0A903B 100%)!important;
  border-radius: 10px;
}

.credit-score .v-slider__track-fill {
  display: none;
}

.credit-score .v-slider__thumb {
  width: 48px;
  height: 48px;
  left: -24px;
  border: 8px solid #fff;
  border-color: #fff!important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.credit-score input {
  text-align: center;
  font-size: 20px;
}

.v-window-item h2 {
  text-transform: uppercase;
  color: #2196F3;
  font-weight: 500;
}

.progress-container {
  background-color: #fff;
  border: 1px solid #E6E6E6;
  margin: 0 auto;
  border-radius: 20px;
  padding: 2px;
  width: 240px;
}

.progress-container .v-progress-linear {
  margin: 0;
}

.progress-container .v-progress-linear__bar__determinate {
  border-radius: 20px;
}

.dialogTEV.v-sheet {
  background-color: #373041;
  border-color: #373041;
  color: #fff;
}

.dialogTEV .v-card__title button {
  background: #2196F3;
}

#pdfvuer {}

.dialogPDF.v-sheet {
  background-color: #efefef;
}

.dialogPDF .page {
  max-width: 800px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
