<template>
  <div v-if="inline" :class="['addressIdenticon', size, 'inline']" v-html="jazzImg" :style="'height:'+size+'px;width:'+size+'px'"></div>
  <div v-else :class="['addressIdenticon', size]" v-html="jazzImg" :style="'height:'+size+'px;width:'+size+'px'"></div>
</template>
<script>
import jazzicon from 'jazzicon'
import { createHash } from 'crypto' 

export default {
  props: ['address', 'size', 'inline'],
  data () {
    return {
      jazzImg: ''
    }
  },
  watch: {
    address(val) {
      if(val) {
        this.jazzImg = this.createIcon({
          address: this.lowerCase(val),
          diameter: this.size
        }).innerHTML
      }
    }
  },
  methods: {
    toHex(str) {
      let result = '';
      for (var i=0; i<str.length; i++) {
        result += str.charCodeAt(i).toString(16);
      }
      return result;
    },
    lowerCase(val) {
      if(typeof val !== 'undefined') {
        return val.toLowerCase()
      }
      return val
    },
    md5(message) {
      return createHash('md5').update(message).digest('hex')
    },
    jsNumberForAddress(address) {
      return parseInt(this.toHex(address).slice(2, 10), 16)
    },
    createIcon(opts) {
      const numericRepresentation = this.jsNumberForAddress(this.md5(opts.address))
      const identicon = jazzicon(opts.diameter, numericRepresentation)
      return identicon
    }
  },
  created () {
    if(this.address) {
      this.jazzImg = this.createIcon({
        address: this.lowerCase(this.address),
        diameter: this.size
      }).innerHTML
    }
  }
}
</script>

<style>
.addressIdenticon {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
    background-color: #212121;
    margin: 0 auto;
}

label.addressIdenticon {
    margin-bottom: -0.9rem;
}

.addressIdenticon.big {
    width: 5rem;
    height: 5rem;
    padding: 0;
}

.addressIdenticon.med {
    width: 3.5rem;
    height: 3.5rem;
    padding: 0;
}

.addressIdenticon.small {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
}

.addressIdenticon.inline {
    display: inline-block;
}

.addressIdenticon.float {
    float: left;
    margin-right: @space-sm;
}

</style>
