import Vue from 'vue'
import './plugins/vuetify'
import './backend/vue-axios'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

Vue.config.productionTip = false

import { sync } from 'vuex-router-sync'

import store from './store'
import router from './router'

router.beforeEach(function (to, from, next) {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title
  
  if (cookies.get('token')) {
    localStorage.token = cookies.get('token');
    store.dispatch('login');
  }

  if(!to.hash) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  next()
})

// sync the router with the vuex store.
// this registers `store.state.route`
sync(store, router)

Vue.use(require('vue-moment'))

Vue.use(require('vue-scrollto'))

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-145824193-1',
  router,
  debug: {
    enabled: false
  },
  linkers: ['clientev.geetsi.es', 'clientev.com'],
  autoTracking: {
    screenview: true,
    exception: true,
    exceptionLogs: false
  }
})

import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: {key: 'AIzaSyCBi6VJGF3EKrG2l_twRMmYHzRtSnmTafU', types: 'address'}
})

import VueSignature from 'vue-signature-pad'
Vue.use(VueSignature)

import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

import VueEvents from 'vue-events'
Vue.use(VueEvents)

import VueOffline from 'vue-offline'
Vue.use(VueOffline)

import VueDeviceDetector from 'vue-device-detector'
Vue.use(VueDeviceDetector)

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

// Dialogs
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
Vue.use(VuetifyDialog, {
  router,
  confirm: {
    actions: {
      false: 'No',
      true: {
        text: 'Yes',
        color: 'primary',
      },
    },
    icon: false,
    width: 500,
  },
  warning: {},
  error: {},
  prompt: {},
})

import TevNavbar from './components/Navbar'
Vue.component('tev-navbar', TevNavbar)

import TevPageTitle from './components/PageTitle'
Vue.component('tev-pagetitle', TevPageTitle)

import App from './App.vue'

require('vue2-animate/dist/vue2-animate.min.css')

import './libs/style.scss'

import vueinterval from 'vue-interval/dist/VueInterval.common'

new Vue({
  mixins:[vueinterval],
  store,
  router,
  render: h => h(App),
}).$mount('#app')
