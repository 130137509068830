export const MUTATION_TYPES = {
  UPDATE_APP_PROPERTIES: 'updateAppProperties',
  RESET_APP_PROPERTIES: 'resetAppProperties',
  LOGIN: 'login',
  LOGOUT: 'logout',
  PDF_FILE: 'SET_PDF_FILE',
  PDF_DATA: 'SET_PDF_DATA',
  PDF_URL: 'SET_PDF_URL',
  PDF_CHANGES: 'SET_PDF_CHANGES',
  REMOVE_PDF_DATA: 'REMOVE_PDF_DATA',
};

export const ACTION_TYPES = MUTATION_TYPES

export const ENTITY_TYPES = [
  'Partnership',
  'Limited Liability Company',
  'Corporation',
  'Non-profit',
  'Sole-proprietorship'
]

export const DESIGNATION_TYPES = [
  'All',
  'Lease',
  'Purchase',
  'Guest'
]

export const INVITATIONS_STATUS_COLORS = {
  'Draft': 'grey',
  'Pending': 'red lighten-2',
  'In Process': 'purple lighten-2',
  'Waiting for Signature': 'indigo lighten-2',
  'Signer Refused': 'orange lighten-2',
  'Coding': 'grey lighten-1',
  'Inactive': 'black lighten-2',
  'Alert Blacklist': 'black lighten-2',
  'Ready': 'teal lighten-2',
  'Active': 'green lighten-2',
}

export const INVITATIONS_STATUS_TYPES = [
  'Draft',
  'Pending',
  'In Process',
  'Waiting for Signature',
  'Signer Refused',
  'Coding',
  'Inactive',
  'Alert Blacklist',
  'Ready',
  'Active',
]

export const INVITATIONS_SORT_TYPES = [
  'id',
  'name',
  'city',
  'state',
  'createdDate',
  'modifiedDate'
]

export const ALIGN_ACTIONS = {
    ALIGN_TOP: "Align Top",
    ALIGN_BOTTOM: "Align Bottom",
    ALIGN_CENTER_VERTICALLY: "Align Vertically",
    ALIGN_CENTER_HORIZONTALLY: "Align Horizontally"
}

export const MATCH_ACTIONS = {
  MATCH_BOX: "Match Box",
  MATCH_WIDTH: "Match Width",
  MATCH_HEIGHT: "Match Height"
}

export const USER_TYPES = [
  'Property Manager',
  'Property Administrator',
  'Board of Directors',
  'President'
]