var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-5 text-xs-center" }, [
    _c("img", {
      staticClass: "mb-2",
      attrs: { src: require("../assets/TE-Icons.svg"), height: "40" },
    }),
    _c("h2", { staticClass: "title mb-4" }, [_vm._v(_vm._s(_vm.title))]),
    _c("p", { staticClass: "text-muted" }, [_vm._v(_vm._s(_vm.subtitle))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }