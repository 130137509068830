var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inline
    ? _c("div", {
        class: ["addressIdenticon", _vm.size, "inline"],
        style: "height:" + _vm.size + "px;width:" + _vm.size + "px",
        domProps: { innerHTML: _vm._s(_vm.jazzImg) },
      })
    : _c("div", {
        class: ["addressIdenticon", _vm.size],
        style: "height:" + _vm.size + "px;width:" + _vm.size + "px",
        domProps: { innerHTML: _vm._s(_vm.jazzImg) },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }