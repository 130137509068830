import Vue from 'vue'
import Router from 'vue-router'

const meta = require('./meta.json')

function route (path, view) {
  return {
    path: path,
    meta: meta[path],
    name: path,
    component: resolve => import(`../pages/${view}View.vue`).then(resolve)
  }
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    route('/', 'Home'),
    route('/login', 'Login'),
    route('/logout', 'Logout'),
    route('/welcome/:propertyId', 'Welcome'),
    route('/form/:propertyId/:step?', 'Form'),
    route('/review/:propertyId/:code', 'Review'),
    route('/forgot', 'Forgot'),
    route('/reset/:key', 'Reset'),
    route('/activate/:key', 'Reset'),
    route('/change-password/:key', 'Reset'),
    route('/password', 'Password'),
    route('/account', 'Account'),
    route('/fcra', 'FCRA'),
    route('/properties', 'Properties'),

    route('/compliance', 'compliance/Invitations'),
    route('/compliance/invitations', 'compliance/Invitations'),
    route('/compliance/invitations/new', 'compliance/Invitation'),
    route('/compliance/invitations/:id(\\d+)', 'compliance/Invitation'),
    route('/compliance/ein-search', 'compliance/EinSearch'),
    route('/compliance/blacklist-search', 'compliance/BlacklistSearch'),
    // route('/compliance/properties', 'compliance/Properties'),
    route('/compliance/documents/:id(\\d+)', 'compliance/Documents'),

    route('/coding/processing', 'coding/CodingProcessing'),
    route('/coding/coding', 'coding/Coding'),
    route('/coding/documents/:id(\\d+)', 'coding/CodingDocuments'),

    // Global redirect for 404
    { path: '*', redirect: '/' },
  ]
})
