<template>
  <div>
    <v-toolbar dark flat app>
      <v-btn icon @click="showDrawer=true" v-if="currentUser&&currentUser.isCompliance&&!backTo">
        <v-icon>menu</v-icon>
      </v-btn>
      <v-btn icon v-if="backTo" :to="backTo">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-btn icon v-if="back" @click="$emit('back')">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title flatclass="headline text-uppercase">
        <span>{{title}}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-if="currentUser.isManager" @click="toggleHelp">
        <v-icon>help_outline</v-icon>
      </v-btn>
      <v-btn icon to="/">
        <img src="../assets/TE-Icons.svg" height="20">
      </v-btn>
      <!--v-btn icon>
      <v-icon>help_outline</v-icon>
    </v-btn-->
      <v-menu offset-y offset-x full-width v-if="currentUser&&currentUser.isLoggedIn">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-tile avatar to="/account">
            <v-list-tile-avatar>
              <Jazzicon :address="currentUser.email" :size="40" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{currentUser.fullname}}</v-list-tile-title>
              <v-list-tile-sub-title>{{currentUser.email}}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile to="/account">
            <v-list-tile-title>Your Account</v-list-tile-title>
          </v-list-tile>
          <v-list-tile to="/logout">
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer v-model="showDrawer" absolute temporary>
      <v-list class="pa-1">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title v-if="currentUser">{{currentUser.fullname}}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-list class="pt-0" dense>
        <v-divider></v-divider>
        <v-list-tile v-for="item in items" :key="item.title" :to="item.to">
          <v-list-tile-avatar>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import Jazzicon from '../components/Jazzicon'
import {
  mapGetters
} from 'vuex'

export default {
  components: {
    Jazzicon
  },
  props: {
    title: String,
    back: Boolean,
    backTo: String,
  },
  data() {
    return {
      showDrawer: false,
      items: [{
        icon: 'email',
        title: 'Property Invitations',
        to: '/compliance/invitations'
      }, {
        icon: 'add_box',
        title: 'New Property Invitation',
        to: '/compliance/invitations/new'
      },
      //   {
      //   icon: 'folder',
      //   title: 'Properties',
      //   to: '/compliance/properties'
      // },
        {
        icon: 'business',
        title: 'Sunbiz Search',
        to: '/compliance/ein-search'
      }, {
        icon: 'assignment_late',
        title: 'Blacklist Search',
        to: '/compliance/blacklist-search'
      }, {
        icon: 'post_add',
        title: 'Coding',
        to: '/coding/coding'
      }]
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  methods: {
    toggleHelp() {
      // eslint-disable-next-line
      Beacon("toggle")
    }
  }
}
</script>
<style>
.v-toolbar--fixed {
  z-index: 4;
}

div#beacon-container .is-mobile {
  top: 56px!important;
}

div#beacon-container .c-BeaconCloseButton.is-mobile {
  top: -56px!important;
  height: 56px!important;
  background: #373041;
}

div#beacon-container .is-mobile .c-Icon {
  display: none!important;
}

div#beacon-container .is-mobile .c-BeaconCloseButton__label span {
  font-size: 16px!important;
  text-transform: uppercase;
  font-weight: 700;
}

</style>
