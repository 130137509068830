import axios from 'axios'
import config from '@/config'

export default axios.create({
  baseURL: config.restUrl,
  transformRequest: [function (data, headers) {
    if(localStorage.token) {
      headers['Authorization'] = 'Bearer ' + localStorage.token
    }
    return Object.prototype.toString.call(data) === "[object String]"? data : JSON.stringify(data)
  }],
  headers: {
    'Content-Type': 'application/json'
  }
})
