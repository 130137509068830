import User from '../models/User'
import { MUTATION_TYPES } from '../libs/constants'

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default {
  [MUTATION_TYPES.LOGIN] (state) {
    state.user = User.from(localStorage.token)
  },
  [MUTATION_TYPES.LOGOUT](state) {
    cookies.remove('token', {
      domain: '.tenantevaluation.com',
    });
    cookies.remove('token', {
      domain: null,
    });
    delete localStorage.token
    state.user = {
      isLoggedIn: false,
      auth: null,
      fullname: null,
      email: null,
      expire: null,
    }
    for (let key in state.app) {
      state.app[key] = null
    }
  },
  [MUTATION_TYPES.UPDATE_APP_PROPERTIES] (state, payload) {
    const obj = {}
    for (let key in state.app) {
      obj[key] = state.app[key]
    }
    for (let key in payload) {
      obj[key] = payload[key]
    }
    state.app = obj
  },
  [MUTATION_TYPES.RESET_APP_PROPERTIES] (state) {
    const obj = {}
    for (let key in state.app) {
      obj[key] = null
    }
    state.app = obj
  },


  //PDF STARTS
  [MUTATION_TYPES.PDF_FILE](state,val) {
    state.pdfFile = val
  },
  
  [MUTATION_TYPES.PDF_DATA](state,val) {
    state.pdfData = val
  },
  [MUTATION_TYPES.PDF_URL](state,val) {
    state.pdfUrl = val;
  },
  [MUTATION_TYPES.PDF_CHANGES](state,val) {
    state.pdfChanges = val;
  },
  [MUTATION_TYPES.REMOVE_PDF_DATA](state) {
    state.pdfFile = null
    state.pdfData = null
    state.pdfUrl = null;
    state.pdfChanges = null;
  },
  //PDF ENDS  
}
