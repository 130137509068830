var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", flat: "", app: "" } },
        [
          _vm.currentUser && _vm.currentUser.isCompliance && !_vm.backTo
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.showDrawer = true
                    },
                  },
                },
                [_c("v-icon", [_vm._v("menu")])],
                1
              )
            : _vm._e(),
          _vm.backTo
            ? _c(
                "v-btn",
                { attrs: { icon: "", to: _vm.backTo } },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              )
            : _vm._e(),
          _vm.back
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              )
            : _vm._e(),
          _c(
            "v-toolbar-title",
            { attrs: { flatclass: "headline text-uppercase" } },
            [_c("span", [_vm._v(_vm._s(_vm.title))])]
          ),
          _c("v-spacer"),
          _vm.currentUser.isManager
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleHelp } },
                [_c("v-icon", [_vm._v("help_outline")])],
                1
              )
            : _vm._e(),
          _c("v-btn", { attrs: { icon: "", to: "/" } }, [
            _c("img", {
              attrs: { src: require("../assets/TE-Icons.svg"), height: "20" },
            }),
          ]),
          _vm.currentUser && _vm.currentUser.isLoggedIn
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", "offset-x": "", "full-width": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g({ attrs: { icon: "" } }, on),
                              [_c("v-icon", [_vm._v("more_vert")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3491234933
                  ),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-list-tile",
                        { attrs: { avatar: "", to: "/account" } },
                        [
                          _c(
                            "v-list-tile-avatar",
                            [
                              _c("Jazzicon", {
                                attrs: {
                                  address: _vm.currentUser.email,
                                  size: 40,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(_vm._s(_vm.currentUser.fullname)),
                              ]),
                              _c("v-list-tile-sub-title", [
                                _vm._v(_vm._s(_vm.currentUser.email)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-tile",
                        { attrs: { to: "/account" } },
                        [_c("v-list-tile-title", [_vm._v("Your Account")])],
                        1
                      ),
                      _c(
                        "v-list-tile",
                        { attrs: { to: "/logout" } },
                        [_c("v-list-tile-title", [_vm._v("Logout")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { absolute: "", temporary: "" },
          model: {
            value: _vm.showDrawer,
            callback: function ($$v) {
              _vm.showDrawer = $$v
            },
            expression: "showDrawer",
          },
        },
        [
          _c(
            "v-list",
            { staticClass: "pa-1" },
            [
              _c(
                "v-list-tile",
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _vm.currentUser
                        ? _c("v-list-tile-title", [
                            _vm._v(_vm._s(_vm.currentUser.fullname)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list",
            { staticClass: "pt-0", attrs: { dense: "" } },
            [
              _c("v-divider"),
              _vm._l(_vm.items, function (item) {
                return _c(
                  "v-list-tile",
                  { key: item.title, attrs: { to: item.to } },
                  [
                    _c(
                      "v-list-tile-avatar",
                      [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                      1
                    ),
                    _c(
                      "v-list-tile-content",
                      [_c("v-list-tile-title", [_vm._v(_vm._s(item.title))])],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }